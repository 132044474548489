const ENV = process.env.ENV || "dev"
const SLOT = process.env.SLOT || "dev"

const REGION = process.env.REGION
  || process.env.REGION_NAME
  || "localhost"

const IS_TEST = process.env.IS_TEST === "true"
const IS_STAGING = process.env.SLOT
  ? process.env.SLOT === "staging"
  : process.env.IS_STAGING === "true"

const config = {
  /**
   * Which environment the app is running in. There are 4 types of environments:
   * - dev: running locally on a developer's machine
   * - prod: running either as a released or staged version remotely
   * - next: running as a stable development version remotely
   *         (can be used as a fallback resource for other environments)
   * - preview: running on a fully dynamic deployment, managed by terraform
   *            through a pull request
   */
  env: ENV,
  /**
   * Azure App Service can have multiple deployment slots, which are essentially copies
   * of the app running on the same underlying VM, but as different container. Currently
   * only the production resources are configured with multiple slot, one for release
   * called "production" and one for staging called "staging". All other resources have
   * only one slot called "production" - this is a bit confusing and should not be compared
   * with a production release of the application
   */
  slot: SLOT,
  /** Running tests */
  isTest: IS_TEST,
  /** dev/pre-releases will be postfixed with a short commit SHA */
  version: process.env.VERSION || "v0.0.1",
  /** Running on a failover region (westeu) */
  isFailover: REGION === "westeu",
  /** Running on production (can be released or staged) */
  isProd: ENV === "prod",
  /** Which deployment region the application is hosted from */
  region: REGION,
  /** Running as a staged deployment */
  isStaging: IS_STAGING,
  /** Running remotely on deployment from pull request */
  isPreview: ENV !== "dev" && ENV !== "prod",
  /** Running locally on developer's computer */
  isDev: ENV === "dev",
  /** The app will send performance metrics (ie. sentry tracing) */
  isInstrumentationEnabled: false,
  /** The app will send usage tracking (ie. segment/mixpanel) */
  isTrackingEnabled: false,
  /**
   * Used for tracking the deployment with the following format:
   * `{config.env}@{config.version}-{config.region}-{config.slot}`
   */
  envHeader: "",
  azAuthLoginDomain: "login-next.placepoint.no",
  azAuthLoginPolicyName: "B2C_1_login",
  azAuthRegisterPolicyName: "B2C_1_register",
  azAuthTestPolicyName: "B2C_1_ropc",
  bonumTenantId: 1,
  placepointTenantId: 2
}

if(!config.isDev && !config.isTest) {
  config.isInstrumentationEnabled = true
}

// if(config.isProd && !config.isTest) {
if(!config.isDev && !config.isTest) {
  config.isTrackingEnabled = true
}

if(config.isTest) {
  config.azAuthLoginPolicyName = "B2C_1_ropc"
}

if(config.isProd) {
  config.azAuthLoginDomain = "login.placepoint.no"
}

config.envHeader
  = `${config.env}@${config.version}-${config.region}-${config.slot}`

export default config
